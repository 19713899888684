@import '@coreui/coreui/scss/coreui';

.default-theme {
  --cui-primary: #1e88e5;
  --cui-secondary: gray;
  --cui-success: #21c1d6;
  --cui-info: #7460ee;
  --cui-warning: #ffa500;
  --cui-danger: red;
  --cui-light: #fff;
  --cui-dark: #000000;
  --cui-gray-100: color-mix(in srgb, var(--cui-light), var(--cui-dark) 5%);
  --cui-gray-200: color-mix(in srgb, var(--cui-light), var(--cui-dark) 20%);
  --cui-gray-300: color-mix(in srgb, var(--cui-light), var(--cui-dark) 30%);
  --cui-gray-400: color-mix(in srgb, var(--cui-light), var(--cui-dark) 40%);
  --cui-gray-500: color-mix(in srgb, var(--cui-light), var(--cui-dark) 50%);
  --cui-gray-600: color-mix(in srgb, var(--cui-light), var(--cui-dark) 60%);
  --cui-gray-700: color-mix(in srgb, var(--cui-light), var(--cui-dark) 70%);
  --cui-gray-800: color-mix(in srgb, var(--cui-light), var(--cui-dark) 80%);
  --cui-gray-900: color-mix(in srgb, var(--cui-light), var(--cui-dark) 90%);
  --cui-button-color: var(--cui-light);
  --cui-input-background: var(--cui-light);
  --cui-body-bg: var(--cui-light) !important;
  --cui-card-bg: var(--cui-light) !important;
  --cui-body-color: var(--cui-gray-700);
  --cui-border-color: transparent !important;
  --cui-btn-active-border-color: transparent !important;
}

.orange-theme {
  --cui-primary: orange;
  --cui-secondary: gray;
  --cui-success: #21c1d6;
  --cui-info: #7460ee;
  --cui-warning: #ffa500;
  --cui-danger: red;
  --cui-light: #fff;
  --cui-dark: #000000;
  --cui-gray-100: color-mix(in srgb, var(--cui-light), var(--cui-dark) 5%);
  --cui-gray-200: color-mix(in srgb, var(--cui-light), var(--cui-dark) 20%);
  --cui-gray-300: color-mix(in srgb, var(--cui-light), var(--cui-dark) 30%);
  --cui-gray-400: color-mix(in srgb, var(--cui-light), var(--cui-dark) 40%);
  --cui-gray-500: color-mix(in srgb, var(--cui-light), var(--cui-dark) 50%);
  --cui-gray-600: color-mix(in srgb, var(--cui-light), var(--cui-dark) 60%);
  --cui-gray-700: color-mix(in srgb, var(--cui-light), var(--cui-dark) 70%);
  --cui-gray-800: color-mix(in srgb, var(--cui-light), var(--cui-dark) 80%);
  --cui-gray-900: color-mix(in srgb, var(--cui-light), var(--cui-dark) 90%);
  --cui-button-color: var(--cui-light);
  --cui-input-background: var(--cui-light);
  --cui-body-bg: var(--cui-light) !important;
  --cui-card-bg: var(--cui-light) !important;
  --cui-body-color: var(--cui-gray-700);
  --cui-border-color: transparent !important;
  --cui-btn-active-border-color: transparent !important;
}

.yellow-theme {
  --cui-primary: #efd510;
  --cui-secondary: gray;
  --cui-success: #21c1d6;
  --cui-info: #7460ee;
  --cui-warning: #ffa500;
  --cui-danger: red;
  --cui-light: #fff;
  --cui-dark: #000000;
  --cui-gray-100: color-mix(in srgb, var(--cui-light), var(--cui-dark) 5%);
  --cui-gray-200: color-mix(in srgb, var(--cui-light), var(--cui-dark) 20%);
  --cui-gray-300: color-mix(in srgb, var(--cui-light), var(--cui-dark) 30%);
  --cui-gray-400: color-mix(in srgb, var(--cui-light), var(--cui-dark) 40%);
  --cui-gray-500: color-mix(in srgb, var(--cui-light), var(--cui-dark) 50%);
  --cui-gray-600: color-mix(in srgb, var(--cui-light), var(--cui-dark) 60%);
  --cui-gray-700: color-mix(in srgb, var(--cui-light), var(--cui-dark) 70%);
  --cui-gray-800: color-mix(in srgb, var(--cui-light), var(--cui-dark) 80%);
  --cui-gray-900: color-mix(in srgb, var(--cui-light), var(--cui-dark) 90%);
  --cui-button-color: var(--cui-light);
  --cui-input-background: var(--cui-light);
  --cui-body-bg: var(--cui-light) !important;
  --cui-card-bg: var(--cui-light) !important;
  --cui-body-color: var(--cui-gray-700);
  --cui-border-color: transparent !important;
  --cui-btn-active-border-color: transparent !important;
}

.green-theme {
  --cui-primary: #42b883;
  --cui-secondary: gray;
  --cui-success: #21c1d6;
  --cui-info: #7460ee;
  --cui-warning: #ffa500;
  --cui-danger: red;
  --cui-light: #fff;
  --cui-dark: #000000;
  --cui-gray-100: color-mix(in srgb, var(--cui-light), var(--cui-dark) 5%);
  --cui-gray-200: color-mix(in srgb, var(--cui-light), var(--cui-dark) 20%);
  --cui-gray-300: color-mix(in srgb, var(--cui-light), var(--cui-dark) 30%);
  --cui-gray-400: color-mix(in srgb, var(--cui-light), var(--cui-dark) 40%);
  --cui-gray-500: color-mix(in srgb, var(--cui-light), var(--cui-dark) 50%);
  --cui-gray-600: color-mix(in srgb, var(--cui-light), var(--cui-dark) 60%);
  --cui-gray-700: color-mix(in srgb, var(--cui-light), var(--cui-dark) 70%);
  --cui-gray-800: color-mix(in srgb, var(--cui-light), var(--cui-dark) 80%);
  --cui-gray-900: color-mix(in srgb, var(--cui-light), var(--cui-dark) 90%);
  --cui-button-color: var(--cui-light);
  --cui-input-background: var(--cui-light);
  --cui-body-bg: var(--cui-light) !important;
  --cui-card-bg: var(--cui-light) !important;
  --cui-body-color: var(--cui-gray-700);
  --cui-border-color: transparent !important;
  --cui-btn-active-border-color: transparent !important;
}

.dark-theme {
  --cui-primary: #1e88e5;
  --cui-secondary: gray;
  --cui-success: #21c1d6;
  --cui-info: #7460ee;
  --cui-warning: #ffa500;
  --cui-danger: red;
  --cui-light: #0a2744;
  --cui-dark: #fff;
  --cui-gray-100: color-mix(in srgb, var(--cui-light), var(--cui-dark) 5%);
  --cui-gray-200: color-mix(in srgb, var(--cui-light), var(--cui-dark) 20%);
  --cui-gray-300: color-mix(in srgb, var(--cui-light), var(--cui-dark) 30%);
  --cui-gray-400: color-mix(in srgb, var(--cui-light), var(--cui-dark) 40%);
  --cui-gray-500: color-mix(in srgb, var(--cui-light), var(--cui-dark) 50%);
  --cui-gray-600: color-mix(in srgb, var(--cui-light), var(--cui-dark) 60%);
  --cui-gray-700: color-mix(in srgb, var(--cui-light), var(--cui-dark) 70%);
  --cui-gray-800: color-mix(in srgb, var(--cui-light), var(--cui-dark) 80%);
  --cui-gray-900: color-mix(in srgb, var(--cui-light), var(--cui-dark) 90%);
  --cui-button-color: var(--cui-dark);
  --cui-input-background: var(--cui-gray-200);
  --cui-body-bg: var(--cui-light) !important;
  --cui-card-bg: var(--cui-light) !important;
  --cui-body-color: var(--cui-gray-700);
  --cui-border-color: transparent !important;
  --cui-btn-active-border-color: transparent !important;
}

#root {
  --cui-table-row-selected-background: var(--cui-primary);
  --cui-table-row-selected-color: var(--cui-light);
}

.header-icon {
  svg {
    color: var(--cui-button-color);
    &:hover {
      color: var(--cui-gray-900);
    }
  }
  button {
    &:focus {
      outline: 0;
    }
    &:focus-visible {
      outline: 0;
    }
  }
}

.dyn-table {
  &.selectable tr {
    cursor: pointer;
  }
  .ant-table {
    background-color: transparent;
    color: var(--cui-gray-700) !important;
  }

  .ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
    background-color: unset;
  }

  .ant-table-thead > tr > * {
    background-color: transparent;
    color: var(--cui-gray-700) !important;
  }

  .ant-table-column-sorter-inner {
    color: var(--cui-dark);
  }
  .ant-dropdown-trigger {
    color: var(--cui-dark);
  }
  .ant-table-tbody {
    tr {
      &.ant-table-row-selected {
        background-color: var(--cui-table-row-selected-background);
        color: var(--cui-table-row-selected-color);
      }
      td {
        padding: 5px 16px;
      }
    }
  }

  .anticon {
    .active {
      color: var(--cui-primary);
    }
  }

  tr {
    .ant-table-cell-row-hover {
      background-color: unset;
      &:hover {
        background-color: unset;
      }
    }
    .ant-table-column-sort {
      background-color: transparent;
    }
  }
  td {
    &:last-of-type {
      width: fit-content;
    }
  }
  th {
    &:last-of-type {
      width: 70px;
    }
  }
  @media screen and (max-width: 600px) {
    table {
      display: flex;
      word-wrap: break-word;
    }
    .ant-table-thead {
      position: absolute;
      left: -999em;
      top: -999em;
      tr {
        display: flex;
        flex-direction: column !important;
        width: fit-content;

        th {
          padding: 0;
        }
      }
    }
    tbody tr {
      float: left;
      width: 100%;
      margin-bottom: 10px;
    }

    tr {
      display: block;
      .ant-table-cell-row-hover {
        background-color: unset !important;
      }
    }

    td {
      display: block;
      float: left;
      width: 100%;
      clear: both;
      padding: 10px 5px;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      &:last-of-type {
        width: 100% !important;
      }
    }
  }
}

.thread-row {
  cursor: pointer;
}

.ant-tabs-content {
  .ant-form-item-label {
    label {
      color: var(--cui-dark);
    }
  }

  .ant-radio-wrapper {
    span {
      color: var(--cui-dark);
    }
  }
}

.ant-form-item .ant-form-item-label > label .ant-form-item-tooltip {
  color: var(--cui-dark);
}

.ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
  background-color: unset;
  .ant-empty-description {
    color: var(--cui-dark);
  }
}

.ant-table-cell {
  background-color: transparent !important;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.ant-tabs-content .ant-layout-content {
  background-color: var(--cui-light);
  margin: 0 1px;
  padding: 20px;
}
.ant-table-column-has-sorters {
  background-color: transparent;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  color: var(--cui-gray-700);
  background-color: transparent;
}

.ant-tabs-tab-btn {
  color: var(--cui-dark);
}

.email-preview-tabs {
  .ant-tabs-content-holder {
    padding-top: 20px;
  }
}

.search-and-add-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  .ant-input-group-wrapper > .ant-input-wrapper > .ant-input-group-addon {
    button {
      background-color: var(--cui-primary) !important;
      color: var(--cui-button-color);
      border: none;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .ant-input-group-wrapper {
    .ant-input-wrapper {
      input {
        color: var(--cui-dark);
        background: var(--cui-input-background);
        &::placeholder {
          color: var(--cui-dark);
          opacity: 0.5;
        }
      }
    }
  }
}

.ant-popover-content {
  margin-left: 200px;
  width: 30%;
  .ant-popover-title {
    color: var(--cui-dark);
  }

  .ant-popover-inner {
    background-color: var(--cui-light);
    .ant-popover-inner-content {
      span {
        color: var(--cui-dark);
      }
    }
  }
  @media only screen and (max-width: 877px) {
    margin-left: 0px;
    width: fit-content !important;
  }
}

.dropdown-menu {
  a {
    color: var(--cui-dark);
    transition: unset !important;
    &:hover {
      background-color: var(--cui-primary) !important;
      color: var(--cui-light);
      transition: unset !important;
    }
  }
  --cui-dropdown-min-width: 0px !important;
}

.ant-tabs {
  height: 100%;
}

.ant-tabs-content-holder {
  border: unset;
  display: flex;
  padding: 0;
}

.ant-tabs-tabpane {
  height: 100%;
}

.hide-content {
  display: none !important;
}

.ant-dropdown-menu {
  background: var(--cui-input-background) !important;
  .ant-dropdown-menu-item {
    &:hover {
      background-color: var(--cui-primary);
    }
  }

  .ant-dropdown-menu-item-selected {
    background-color: var(--cui-gray-200);
  }
}

.ant-dropdown > .ant-dropdown-menu {
  background: var(--cui-input-background);
  .ant-dropdown-menu-item {
    &:hover {
      background-color: var(---cui-input-background);
    }
  }

  .ant-dropdown-menu-item-only-child {
    .ant-dropdown-menu-title-content {
      color: var(--cui-dark);
    }
  }

  .ant-dropdown-menu-item-selected {
    background-color: var(--cui-input-background);
  }

  .ant-dropdown-menu-title-content {
    span {
      color: var(--cui-dark);
    }
  }
}

.ant-dropdown > .ant-table-filter-dropdown {
  background: var(--cui-gray-100);
  span {
    color: var(--cui-dark);
  }
}
.ant-checkbox-wrapper {
  span {
    color: var(--cui-dark);
  }
}

.flags {
  width: 20px;
}

.homepage-row {
  p {
    background-color: unset;
  }
  .highcharts-background {
    fill: transparent;
  }

  .highcharts-plot-background {
    fill: transparent;
  }

  .highcharts-root text {
    fill: var(--cui-dark) !important;
  }

  .highcharts-tooltip text {
    fill: black !important;
  }

  .highcharts-data-label text {
    fill: var(--cui-dark);
  }

  .highcharts-text-outline {
    stroke: none;
  }
}

.fullscreen-container {
  position: fixed;
  top: 75px;
  right: 12px;
  z-index: 2;

  .fullscreen-icon {
    color: var(--cui-dark);
    height: 20px;
    width: 20px;
    &:hover {
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 991px) {
    display: none;
  }
}

.side-bar {
  .side-bar-toggler {
    right: -12px;
    top: 10px;
    position: absolute;
    .side-bar-icon {
      background-color: var(--cui-primary);
      color: var(--cui-button-color);
      display: flex;
      height: 25px;
      width: 25px;
      border-radius: 100%;
      text-align: center;
      justify-content: center;
      align-items: center;
      z-index: 999;
      &:hover {
        cursor: pointer;
        color: var(--cui-gray-900);
      }
    }
  }
}

//Sidebar navGroup childs

// .sidebar-nav {
//   .nav-group {
//     .nav-group-items {
//       opacity: 1 !important;
//       visibility: visible !important;
//       height: 100% !important;
//       overflow: visible !important;
//       &:not(.show) {
//         height: 0;
//       }
//     }
//   }
// }

.sidebar-narrow {
  .nav-group {
    .nav-group-items {
      .nav-link {
        padding-left: 60%;
      }
      .nav-icon {
        width: 13px;
        height: 13px;
      }
    }
  }
}

.nav-group-items {
  padding-inline-start: 20px;
}

.sidebar {
  .sidebar-nav .nav-link {
    background: var(--cui-light);
    color: var(--cui-dark);

    &:hover {
      background: var(--cui-primary);
      color: var(--cui-light);
      .nav-icon > * {
        color: var(--cui-light);
      }
    }
    &.active {
      background: var(--cui-primary);
      color: var(--cui-light);
      .nav-icon > * {
        color: var(--cui-light);
      }
    }
    .nav-icon > * {
      color: var(--cui-dark);
    }
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
}

@media only screen and (max-width: 991.98px) {
  .sidebar-backdrop.show {
    display: none;
  }
}

.sidebar-narrow-unfoldable {
  position: relative;
}

.agent-refresh-table {
  color: var(--cui-dark);
}

.form-pagination {
  align-self: flex-end;
  margin-top: 20px;
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      color: var(--cui-primary);
    }
  }

  .ant-select > .ant-select-selector {
    background-color: var(--cui-primary);
    color: var(--cui-button-color);
  }
  .ant-pagination-item {
    background-color: var(--cui-primary);
  }

  .ant-select-arrow > span {
    color: var(--cui-dark);
  }

  .ant-pagination-item-active {
    color: var(--cui-button-color);
    border: none;
  }
}

.ant-pagination {
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      color: var(--cui-primary);
    }
  }
}

.dyn-form-modal {
  background-color: var(--cui-gray-100);
  color: var(--cui-dark);
  padding-bottom: 0%;
  border-radius: 0.375rem;
  .ant-modal-header {
    background-color: var(--cui-gray-100);
    .ant-modal-title {
      color: var(--cui-dark);
    }
  }
  .ant-form-item-label {
    label {
      color: var(--cui-dark);
    }
  }

  .ant-input-password {
    background: var(--cui-input-background);
  }

  .ant-select {
    .ant-select-selector {
      background-color: transparent;
      color: var(--cui-dark);
      background: var(--cui-input-background);
      .ant-select-selection-item {
        color: var(--cui-dark);
      }
      &:focus {
        background-color: unset;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .ant-checkbox-inner {
    background-color: var(--cui-input-background);
  }

  .ant-checkbox-wrapper-checked {
    .ant-checkbox-inner {
      background-color: #1677ff;
    }
  }

  textarea {
    color: var(--cui-dark);
    background: var(--cui-input-background);
    &::placeholder {
      color: var(--cui-dark);
      opacity: 0.5;
    }
    &:focus {
      background-color: unset;
    }
    &:hover {
      background-color: unset;
    }
  }

  input {
    color: var(--cui-dark);
    background: var(--cui-input-background);
    &::placeholder {
      color: var(--cui-dark);
      opacity: 0.5;
    }
    &:focus {
      background-color: unset;
    }
    &:hover {
      background-color: unset;
    }
  }
  .ant-modal-content {
    background-color: var(--cui-gray-100);
    color: var(--cui-dark);
    p {
      background: unset;
    }
  }

  .form-footer-buttons {
    gap: 10px;
  }
}

.ant-input-affix-wrapper .anticon.ant-input-password-icon {
  color: var(--cui-dark);
}

.permissions-transfer {
  .ant-transfer-list
    > .ant-transfer-list-body
    > .ant-transfer-list-content
    > .ant-transfer-list-content-item
    > span {
    color: var(--cui-dark);
  }

  .ant-transfer-list > .ant-transfer-list-header {
    background: var(--cui-input-background);
    color: var(--cui-dark);
  }

  .ant-transfer-list
    > .ant-transfer-list-body
    > .ant-transfer-list-body-search-wrapper
    > .ant-input-affix-wrapper {
    background-color: var(--cui-input-background);
    .ant-input {
      color: var(--cui-dark);
    }
    .ant-input-prefix {
      .anticon-search {
        color: var(--cui-dark);
      }
    }
    .ant-input-suffix {
      .ant-input-clear-icon {
        color: var(--cui-dark);
      }
    }
    &:hover {
      border-color: #4096ff;
    }
  }

  .ant-transfer-list
    > .ant-transfer-list-body
    > .ant-transfer-list-body-not-found
    > .ant-empty
    > .ant-empty-description {
    color: var(--cui-dark);
  }

  .ant-transfer-list
    > .ant-transfer-list-body
    > .ant-transfer-list-content
    > .ant-transfer-list-content-item-checked {
    background-color: var(--cui-primary);
  }
  .ant-transfer-operation > button > .ant-btn-icon {
    color: var(--cui-dark);
  }

  justify-content: center;

  @media only screen and (max-width: 877px) {
    flex-wrap: wrap;
  }
}

.permissions-label > .ant-form-item-row > .ant-form-item-label {
  text-align: center;
}

.dynamic-footer {
  padding: 10px;
  p {
    margin: 0;
  }
  a {
    text-decoration: none;
    font-weight: 600;
    color: var(--cui-primary);
    &:hover {
      color: var(--cui-primary-dark);
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-date-panel-container {
    background-color: var(--cui-gray-100);
    color: var(--cui-dark);
  }

  .ant-picker-cell-inner {
    color: var(--cui-dark);
  }

  .ant-picker-content th {
    color: var(--cui-gray-500);
  }

  .ant-picker-header-view {
    color: var(--cui-dark);
  }

  .ant-picker-header button {
    color: var(--cui-button-color);
  }
}

.ant-picker {
  .anticon {
    color: var(--cui-dark);
    &:hover {
      opacity: 0.8;
    }
  }
}

.ant-picker {
  color: var(--cui-dark);
  &:focus {
    background-color: unset;
  }
  .ant-picker-input-placeholder {
    input {
      color: var(--cui-dark);
      background-color: unset;
    }
  }
}

// .ant-progress-inner {
//   display: flex !important;
// }

.card-stats {
  @media only screen and (min-width: 877px) {
    width: 100% !important;
  }
}

//declare animation pulse
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.scroll-x-mobile {
  @media only screen and (max-width: 877px) {
    overflow-x: auto;
  }
}

.ant-modal-footer > .ant-btn-default {
  border: none;
  &:not(:disabled) {
    &:not(.ant-btn-disabled) {
      color: var(--cui-button-color);
      background-color: var(--cui-danger);
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.ant-select-arrow {
  color: var(--cui-primary);
}

.ant-select-dropdown {
  background-color: var(--cui-light);
  color: var(--cui-light);
}

.ant-select-item-option > .ant-select-item-option-content {
  color: var(--cui-dark);
}

.ant-select-dropdown {
  .ant-select-item-option-selected {
    &:not(.ant-select-item-option-disabled) {
      background-color: var(--cui-primary);
    }
  }
}

.ant-select-item-option-active {
  &:not(.ant-select-item-option-disabled) {
    background-color: var(--cui-primary);
    color: var(--cui-light);
    &:hover {
      background-color: var(--cui-primary);
      color: var(--cui-light);
    }
  }
}
.ant-picker-outlined {
  background-color: transparent;

  color: var(--cui-dark);
  &:focus-within {
    background-color: var(--cui-input-background);
  }
  &:hover {
    background-color: var(--cui-gray-100);
  }
}

.ant-picker-dropdown > .ant-picker-range-wrapper > .ant-picker-range-arrow {
  display: none;
}

.container-background {
  background: var(--cui-gray-100);
  padding: 10px;
  margin-right: 0;
  @media only screen and (max-width: 770px) {
    padding: 10px 0;
  }
}

.login-page {
  background: url('../assets/compres.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login-text-container {
  background-color: #003f6e !important;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70vh;
}

.animation-container {
  transition: visibility 0.5s ease-in-out;
}

.margin-bottom-container {
  overflow-x: auto;
  // min-height: 100%;
  @media only screen and (max-width: 770px) {
    height: 100%;
    width: 100%;
    margin-bottom: 1rem !important;
  }
}

.nav-logo-link {
  div {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen {
  height: calc(100vh - 63px);
}

.ant-form-item {
  margin-bottom: 10px;
}

p {
  color: var(--cui-dark);
  background: var(--cui-light);
}
.card {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

  @media (max-width: 877px) {
    width: 100% !important;
  }
  &.card-c {
    @media (max-width: 877px) {
      min-height: 220px;
    }
  }
}

.card-body-s {
  display: flex;
  justify-content: space-between;
}

.text-medium-emphasis {
  background: none;
}

.card-f-line {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: -0.8rem;
  @media (max-width: 877px) {
    font-size: 1rem;
  }
}
.card-s-line {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
}
.card-td-line {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0;
  @media (max-width: 877px) {
    font-size: 1rem;
  }
}

.icon-container {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-container {
  margin: 0;
  padding: 15px;
}
.header {
  background-color: var(--cui-primary);
}

.navbar-left {
  display: flex;
  gap: 10px;
  h2 {
    color: var(--cui-button-color);
    width: 234px;
  }
  a {
    text-decoration: none;
  }
  @media only screen and (max-width: 770px) {
    display: none;
  }
}

.container-hide-mobile {
  @media only screen and (max-width: 770px) {
    display: none;
  }
}

.header-parent-container {
  @media only screen and (max-width: 770px) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.navigation-header-container {
  z-index: 10;
  padding-right: 0;

  .menu-toggler > button {
    display: none;
  }
  .logo-container {
    display: none;
  }

  @media only screen and (max-width: 991px) {
    display: flex !important;
    padding-bottom: 10px;
    height: 60px;

    .navigation-container {
      display: flex;
      flex-direction: row-reverse;
      height: 100%;
      justify-content: flex-end;
    }
    .logo-container {
      display: block;
      height: 100% !important;
    }
    .navigation-logo-link {
      height: 100% !important;
    }
    .menu-toggler > button {
      display: block;
      color: var(--cui-light);
    }

    .links-container {
      height: fit-content;
      width: 100%;
      display: flex;
      padding: 10px;
      margin-top: 10px;
      margin-right: 10px;
      flex-direction: column;
      align-items: center;
      font-size: 1.3rem;
      background-color: var(--cui-primary);
      border-radius: 0 0 10px 10px;
      a {
        color: var(--cui-light);
      }
      .active {
        font-weight: bold;
      }
    }
  }
}

.nav-group {
  list-style-type: none;
  li {
    list-style-type: none;
  }
  .nav-link-item {
    display: flex;
    flex-direction: column;
  }
}

.nav-item {
  list-style-type: none;
}

.ant-modal-footer {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
}

.menu-container {
  position: absolute;
  right: 0;
  top: 15px;
  color: var(--cui-dark);
}

.card-body-login > p {
  color: var(--cui-gray-100);
  background: none;
  font-size: 1rem;
}

.login-card {
  width: '44%';
  box-shadow:
    rgba(136, 165, 191, 0.2) 6px 2px 16px 0px,
    rgba(255, 255, 255, 0.1) -6px -2px 16px 0px;
}

.homepage-row > p {
  margin-bottom: 0;
  padding-right: 10px;
}

.restriction-page {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.icons-container {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  align-items: center;
  svg {
    cursor: pointer;
  }
  @media only screen and (max-width: 770px) {
    justify-content: space-between !important;
  }
}

.pencil-icon {
  color: var(--cui-warning);
  @media only screen and (max-width: 770px) {
    padding-left: 0;
  }
}

.delete-icon {
  color: var(--cui-danger);
  @media only screen and (max-width: 770px) {
    padding-left: 0;
  }
}

.download-icon {
  color: var(--cui-dark);
  @media only screen and (max-width: 770px) {
    padding-left: 0;
  }
}

.play-icon {
  color: var(--cui-success);
  @media only screen and (max-width: 770px) {
    padding-left: 0;
  }
}

.stop-icon {
  color: var(--cui-info);
  @media only screen and (max-width: 770px) {
    padding-left: 0;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  @media only screen and (max-width: 770px) {
    h3 {
      font-size: 20px;
    }

    .search-and-add-container {
      width: 200px;
    }
  }
}

.graphs-container {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
}

.connect-buttons {
  display: flex;
  align-items: center;
  gap: 6px;
  &:where(.css-dev-only-do-not-override-11xg00t).ant-btn:not(.ant-btn-icon-only)
    > .ant-btn-icon.ant-btn-loading-icon,
  &:where(.css-dev-only-do-not-override-11xg00t).ant-btn:not(.ant-btn-icon-only)
    > .ant-btn-icon:not(:last-child) {
    margin-inline-end: 0;
  }
}

.ant-modal-close > .ant-modal-close-x {
  color: var(--cui-dark);
}

.upload-icon {
  color: var(--cui-dark);
}

.dyn-btn-primary {
  background-color: var(--cui-primary) !important;
  color: var(--cui-button-color) !important;
  border: none;
  border-radius: 6px !important;
  &:hover {
    opacity: 0.8 !important;
  }
  .ant-btn {
    background-color: var(--cui-primary) !important;
    color: var(--cui-button-color) !important;
    border: none;
  }

  .ant-tabs-tabpane & {
    margin-right: 10px;
  }
}

.dyn-btn-secondary {
  background-color: var(--cui-danger) !important;
  color: var(--cui-button-color) !important;
  border: none;
  &:hover {
    opacity: 0.8 !important;
  }
}

.ant-form .ant-form-item .ant-row {
  flex-direction: column;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  padding: 3px 8px;
  background: #eeeeee40;

  &hover {
    background: #eeeeee;
  }
}

input::placeholder {
  color: var(--cui-dark) !important;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--cui-secondary);
  border-radius: 10px;
}

::-webkit-input-placeholder {
  background: var(--cui-input-background);
}

::-moz-placeholder {
  background: var(--cui-input-background);
}
:-ms-input-placeholder {
  background: var(--cui-input-background);
}

.cell-content {
  position: relative;
  display: flex;
  align-items: center;

  &:hover .copy-button {
    opacity: 1;
  }
}

.copy-button {
  margin-left: 8px;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 99999;
}
